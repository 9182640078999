<!--消息管理-指令发送-->
<template>
  <section>
    <!-- 工具条 -->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form :inline="true" :model="filter" size="mini">
        <el-form-item>
          <el-cascader
            v-model="filter.owner"
            @change="getData"
            placeholder="所属单位"
            :options="customerTree"
            :props="orgProps"
            clearable
            filterable
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getData">刷新</el-button>
          <el-button type="primary" @click="handleAdd">发送指令</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <!-- 列表 -->
    <el-col :span="24">
      <el-table
        :data="datalist.content"
        size="mini"
        border
        highlight-current-row
        v-loading="loading"
        @selection-change="selsChange"
        :max-height="clientHeight"
        style="width: 100%"
      >
        <el-table-column type="index" label="序号" align="center" width="55" />
        <el-table-column
          prop="MN"
          label="设备编号"
          show-overflow-tooltip
          width="180"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="Cmd"
          label="指令"
          width="200"
          show-overflow-tooltip
          header-align="center"
        />
        <el-table-column
          prop="Owner"
          label="所属单位"
          show-overflow-tooltip
          :formatter="customerFormatter"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="Addr"
          label="安装地址"
          show-overflow-tooltip
          header-align="center"
        />
        <el-table-column
          prop="Sender"
          label="发送者"
          :formatter="userFormatter"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="SendAt"
          label="发送日期"
          :formatter="dateFormat"
          width="140"
          align="center"
          header-align="center"
        />
      </el-table>
    </el-col>

    <!-- 工具条 -->
    <el-col :span="24" class="toolbar">
      <el-pagination
        small
        background
        @size-change="(v) => handlePageChange(v, 'size')"
        @current-change="(v) => handlePageChange(v, 'page')"
        :current-page.sync="filter.page"
        :page-sizes="filter.pageSizes"
        layout="total, sizes, prev, pager, next, jumper"
        :total="datalist.total"
        style="display: inline-block; margin-left: 15px"
      >
      </el-pagination>
    </el-col>

    <!--编辑界面-->
    <el-dialog
      :title="dlg.title"
      :visible.sync="dlg.visible"
      width="30%"
      @close="handlerClose"
    >
      <el-form
        :model="formData"
        label-width="80px"
        :rules="formRule"
        ref="form"
        size="mini"
      >
        <el-col :span="12">
          <el-form-item prop="MN" label="设备">
            <el-select
              @change="hasChange"
              v-model.trim="formData.MN"
              placeholder="设备编号"
              filterable
              clearable
            >
              <el-option
                v-for="item in device"
                :key="item.Id"
                :label="item.MN"
                :value="item.MN"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="Cmd" label="指令">
            <el-select
              @change="hasChange"
              v-model.trim="formData.Cmd"
              clearable
            >
              <el-option
                v-for="item in command"
                :key="item.K"
                :label="item.V"
                :value="item.Kind"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click.native="dlg.visible = false"
          >取消</el-button
        >
        <el-button type="primary" size="mini" @click.native="handleSubmit"
          >提交</el-button
        >
      </div>
    </el-dialog>
  </section>
</template>

<script>
import { mapState } from "vuex";
import {
  dateFormater,
  getUserInfo,
  dataFormatter,
  getPname,
  join,
} from "@/util/index";

export default {
  data() {
    return {
      changeState: false,
      index: 0, // 记录更新index
      sels: [], // 列表选中列
      loading: false,
      device: null,
      command: null,
      datalist: {},
      userInfo: {},
      dlg: {
        visible: false,
        title: "",
      },
      filter: {
        owner: null,
        page: 1,
        size: 20,
        pageSizes: [20, 30, 50],
      },
      formData: {},
      formRule: {
        MN: [{ required: true, message: "设备不可为空", trigger: "blur" }],
        Cmd: [{ required: true, message: "指令不可为空", trigger: "blur" }],
      },
    };
  },
  computed: {
    ...mapState({
      orgProps: (state) =>
        Object.assign({}, state.props, { label: "Org", checkStrictly: true }),
    }),
    ...mapState(["props", "customerTree", "user", "clientHeight"]),
  },
  created() {
    this.userInfo = getUserInfo();
    this.$post("admin/listAllDevice").then((res) => {
      this.device = res;
    });
    this.$post("admin/listReferParam", { code: "DEVICE_COMMAND" }).then(
      (res) => {
        this.command = res;
      }
    );
    this.getData();
  },
  methods: {
    hasChange() {
      this.changeState = true;
    },
    dateFormat(r, c) {
      return dateFormater(r[c.property], true);
    },
    customerFormatter(r, c) {
      return getPname(this.customerTree, r[c.property], "Org").join("/");
    },
    userFormatter(r, c) {
      return dataFormatter(r[c.property], this.user);
    },
    getData: function () {
      this.loading = true;
      var para = {
        StartAt: (this.filter.page - 1) * this.filter.size,
        Size: this.filter.size,
      };
      para.Param = {};
      if (this.filter.owner) {
        para.Param["Owner"] = join(this.filter.owner);
      }
      this.$post("admin/listCommandRecord", para).then((res) => {
        this.datalist = res;
        this.loading = false;
      });
    },
    handleRefresh() {
      this.filter.page = 1;
      this.getData();
    },
    selsChange: function (sels) {
      this.sels = sels;
    },
    handlePageChange(val, field) {
      this.filter[field] = val;
      this.getData();
    },
    handleAdd: function () {
      this.dlg.title = "指令发送";
      this.dlg.visible = true;
      this.formData = {};
    },
    handleSubmit: function () {
      if (!this.changeState) {
        this.dlg.visible = false;
        return;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {})
            .then(() => {
              this.$post("admin/commandHandler", this.formData)
                .then(() => {
                  this.handleRefresh();
                  this.dlg.visible = false;
                })
                .catch(() => {});
            })
            .catch(() => {});
        }
      });
    },
    handlerClose: function () {
      this.changeState = false;
      if (this.$refs["form"]) {
        this.$refs["form"].resetFields();
      }
    },
  },
};
</script>
